<template>
  <div class="align-items-center mx-2">
    {{ modelValue }}
    <button
      class="btn btn-secondary btn-sm ms-2"
      v-on:click="print()"
      v-if="(id > 0 && main > 0) || variantId > 0"
    >
      <BaseIcon name="printer" />
    </button>
  </div>
</template>
<script>
import BaseIcon from "@/components/icons/BaseIcon";

export default {
  name: "IngredientsCost",
  components: { BaseIcon },
  props: {
    id: Number,
    row: Object,
    modelValue: Array,
    main: Number,
    variantId: Number,
  },
  methods: {
    showModal() {
      this.$refs.modal.showModal();
    },
    print() {
      window.apps.callEvent("print:ingredientCost", this.variantId ?? this.id);
    },
  },
  emits: ["update:modelValue", "update"],
};
</script>
